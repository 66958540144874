<template>
  <div>
    <a-modal
      width="1100px"
      :title="Title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      footer
      @cancel="cancel"
    >
      <div style="height:600px;overflow:auto;padding:10px;">
        <a-form
          ref="formRef"
          :model="formState"
          :rules="rules"
        >
          <a-row>
            <a-col :span="8">
              <a-form-item
                label="问题类型："
              >
                <a-select
                  ref="select"
                  style="width:80%"
                  v-model:value="formState.type"
                  allowClear
                  :options="options1"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item
                label="问题类别："
              >
                <a-select
                  ref="select"
                  style="width:80%"
                  v-model:value="formState.categoryId"
                  allowClear
                  :options="options2"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item
                label="试题描述："
              >
                <a-input
                  v-model:value="formState.title"
                  allowClear
                  style="width:80%"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="20" />
            <a-col :span="4">
              <a-button @click="reset">重置</a-button>
              <a-button
                type="primary"
                 
                @click="query"
              >
                查询
              </a-button>
            </a-col>
          </a-row>
          <a-table
            style="margin-top: 10px"
            :columns="columns"
            :data-source="sourceData"
            :pagination="false"
            :row-key="record => record.questionId"
          >
            <template #operation="{ text, record,index }">
              <div v-if="record.status==1">
                 <a-button
                  type="link"
                  style=" "
                  @click="add(record)"
                  v-if="handleGetShow(record)"
                >添加</a-button>
              </div>
            </template>
          </a-table>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>
  <script>
import { defineComponent } from "vue";
import {QuestionController_find,find} from "@/api/api"

const columns = [
{
    title: "序号",
    width: 60,
    dataIndex: "index",
    customRender: (row) => row.index + 1,
  },
  {
    title: "试题描述 ",
    dataIndex: "title",
    ellipsis: true,
    width: 200
  },
  {
    title: "问题类别",
    dataIndex: "category",
  },

  {
    title: "问题类型",
    customRender: (text) => {
      return (text.text == 1 ? "单选题" : (text.text == 2 ? "多选题" : "判断题"))
    },
    dataIndex: "type",
  },
  {
    title: "问题状态",
    dataIndex: "status",
    customRender: (text) => (text.text == 0 ? "未发布" : "已发布"),
  },
  {
    title: "操作",
    dataIndex: "operation",
    width:200,
    slots: { customRender: "operation" },
  },
];
export default defineComponent({
  name: "index",
  inject: ['addTable'],
  props: {
    checkIds: String,
    FormData: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      index: '',
      options1: [{
        value: 1,
        label: "单选题",
      },
      {
        value: 2,
        label: "多选题",
      },
      {
        value: 3,
        label: "判断题",
      },
      ],
      options2: [ ],
      Title: '',
      selectedRowKeys: [],
      sourceData: [],
      columns,
      visible: false,
      confirmLoading: false,
      formState: {
        type:'',
        categoryId: '',
        title:''
      },
      rules: {
        title: [{ required: true, message: '不能为空', trigger: 'blur' }],
      }
    };
  },
  methods: {
    reset(){
      this.formState={
        type:'',
        categoryId: '',
        title:''
      }
    },
    query(){
      QuestionController_find(this.formState).then(res=>{
        this.sourceData=res.data
      })
    },
    cancel() {
      this.formState={
        type:'',
        categoryId: '',
        title:''
      }
      this.visible = false;
    },
    add(row) {
      this.addTable(row)
    },
    showModal(index, val) {
      this.index = index
      this.Title = val
      this.visible = true;
      QuestionController_find().then(res=>{
        this.sourceData=res.data
      })
      find({ type: 1 }).then((res) => {
        this.options2 = res.data.map((item) => ({
          ...item,
          value: item.categoryId,
          label: item.name,
        }));
      });
    },
    handleGetShow(row) {
      return !this.checkIds.includes(row.questionId)
    },
  },
});
  </script>
  <style lang="less">
</style>
      