<template>
  <div>
    <a-modal
      width="1100px"
      :title="Title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="cancel"
    >
      <div style="height:600px;overflow:auto;padding:10px;">
        <a-form
          ref="formRef"
          :model="formState"
          :rules="rules"
        >
          <a-form-item
            label="试卷名称："
            name="title"
          >
            <a-input
              allow-clear
              placeholder="请输入"
              v-model:value="formState.title"
              style="width:80%"
            />
          </a-form-item>
          <a-form-item label="试卷描述：">
            <a-textarea
              allow-clear
              placeholder="请输入"
              v-model:value="formState.comment"
              style="width:80%"
            />
          </a-form-item>
          <a-form-item
            label="考题数量："
            name="num"
          >
            <a-input-number
              v-model:value="formState.num"
              style="width:80%"
            />
          </a-form-item>
          <a-form-item
            label="试卷总分："
            name="totalPoints"
          >
            <a-input
              disabled
              placeholder="根据选题分值自动计算"
              v-model:value="formState.totalPoints"
              style="width:80%"
            />
          </a-form-item>
          <div style="margin-bottom:5px">
            <span>问题类别：</span>
            <a-checkbox-group
              v-model:value="formState.categoryIds"
              name="checkboxgroup"
              :options="plainOptions"
            />
            <a-button
              type="primary"
              @click="query"
              :disabled="disabled"
            >查询</a-button>
          </div>
          <div style="width:100%;border: 1px solid rgb(225, 225, 225);padding:10px;position:relative;">
            <div style="height:40px;line-height: 40px;background:rgb(225, 225, 225);">
              <h2 style="margin-left:10px">选题信息</h2>
            </div>
            <a-table
              style="margin-top: 10px"
              :columns="columns"
              :data-source="examinationPaperToQuestions"
              :pagination="false"
              :row-key="record => record.questionId"
            >
              <template #score="{column, text, record }">
                <template v-if="column.dataIndex === 'score'">
                  <div class="editable-cell">
                    <div
                      v-if="editableData[record.questionId]"
                      class="editable-cell-input-wrapper"
                    >
                      <a-input v-model:value="editableData[record.questionId].score" />
                      <check-outlined
                        class="editable-cell-icon-check"
                        @click="save(record.questionId)"
                      />
                    </div>
                    <div
                      class="editable-cell-text-wrapper"
                      v-else
                    >
                      {{ text || ' ' }}
                      <edit-outlined
                        class="editable-cell-icon"
                        @click="edit(record.questionId)"
                      />
                    </div>
                  </div>
                </template>
              </template>
            </a-table>
          </div>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import AddTitle from "./AddTitle.vue";
import { CheckOutlined, EditOutlined, HomeOutlined } from '@ant-design/icons-vue';
import { cloneDeep } from 'lodash-es';
import { find, randomFind_find, ExaminationPaperController_post, ExaminationPaperController_id, ExaminationPaperController_put } from "@/api/api"
const columns = [
  {
    title: "序号",
    width: 60,
    dataIndex: "index",
    customRender: (row) => row.index + 1,
  },
  {
    title: '问题内容',
    dataIndex: 'title',
    width: 400,
    ellipsis: true,
  },
  {
    title: '问题类别',
    key: 'category',
    dataIndex: 'category',
  },
  {
    title: '问题类型',
    key: 'duration',
    dataIndex: 'duration',
    customRender: (text) => {
      return (text.text == 1 ? "单选题" : (text.text == 2 ? "多选题" : "判断题"))
    },
    dataIndex: "type",
  },
  {
    title: '分值',
    key: 'score',
    dataIndex: 'score',
    slots: { customRender: "score" },
  },
];
const Form = {
  title: '',
  comment: '',
  totalPoints: 0,
  num: '',
  categoryIds: []
}
export default defineComponent({
  components: {
    EditOutlined,
    CheckOutlined,
  },
  name: "index",
  props: {
    Title: {
      type: String,
      required: false,
    },
    FormData: {
      type: String,
      required: false,
    },
  },
  watch: {
    'formState.num'(newData, oldData) {
      if (this.formState.num) {
        this.disabled = false
      } else {
        this.disabled = true
      }
    }
  },
  data() {
    return {
      editableData: {},
      editor: false,
      disabled: true,
      plainOptions: [],
      examinationPaperToQuestions: [],
      columns,
      visible: false,
      confirmLoading: false,
      formState: {
        ...Form
      },
      rules: {
        title: [{ required: true, message: '不能为空', trigger: 'blur' }],
        comment: [{ required: true, message: '不能为空', trigger: 'blur' }],
        num: [{
          required: true, message: '不能为空', trigger: 'change', transform: (value) => {
            if (value) {
              return value.toString()
            }
            return value
          }
        }],
        totalPoints: [{
          required: true, message: '不能为空', trigger: 'change', transform: (value) => {
            if (value) {
              return value.toString()
            }
            return value
          }
        }],
      }
    };
  },
  components: {
    AddTitle
  },
  created() { },
  methods: {
    edit(questionId) {
      if (this.examinationPaperToQuestions) {
        this.editableData[questionId] = cloneDeep(this.examinationPaperToQuestions.filter(item => questionId === item.questionId)[0]);
      }
    },
    save(questionId) {
      Object.assign(this.examinationPaperToQuestions.filter(item => questionId === item.questionId)[0], this.editableData[questionId]);
      delete this.editableData[questionId];
      this.formState.totalPoints = 0
      this.examinationPaperToQuestions.forEach(res => {
        this.formState.totalPoints = Number(this.formState.totalPoints) + Number(res.score)
      })
    },
    query() {
      const params = {
        num: this.formState.num,
        categoryIds: this.formState.categoryIds,
      }
      randomFind_find(params).then(res => {
        this.examinationPaperToQuestions = res.data
        this.formState.totalPoints = 0
        this.examinationPaperToQuestions.forEach(res => {
          this.formState.totalPoints = Number(this.formState.totalPoints) + Number(res.score)
        })
        this.formState.num = this.examinationPaperToQuestions.length
      })
    },
    delClick(index) {
      this.examinationPaperToQuestions.splice(index, 1)
    },
    add() {
      this.$refs.AddTitle.showModal('添加题目')
    },
    cancel() {
      this.$refs.formRef.resetFields()
      this.formState = { ...Form }
      this.editor = false
      this.visible = false;
    },
    handleOk() {
      this.$refs.formRef.validate().then(() => {
        this.confirmLoading = true;
        const params = {
          ...this.formState,
          examinationPaperToQuestions: this.examinationPaperToQuestions,
          type: 2
        }
        if (!this.editor) {
          ExaminationPaperController_post(params).then(res => {
            this.visible = false;
            this.confirmLoading = false;
            this.$parent.refresh();
          })
        } else {
          ExaminationPaperController_put(params).then(res => {
            this.visible = false;
            this.confirmLoading = false;
            this.$parent.refresh();
          })
        }
      });
    },
    showModal(val) {
      this.visible = true;
      this.editor = false
      this.plainOptions = []
      if (val) {
        this.editor = true
        ExaminationPaperController_id(val).then(res => {
          this.formState = { ...res.data }
          this.examinationPaperToQuestions = res.data.examinationPaperToQuestions
          this.formState.num = this.examinationPaperToQuestions.length
          this.formState.totalPoints = 0
          this.examinationPaperToQuestions.forEach(res => {
            this.formState.totalPoints = Number(this.formState.totalPoints) + Number(res.score)
          })
        })
      }
      find({ type: 1 }).then(res => {
        res.data.map(res => {
          this.plainOptions.push({
            value: res.categoryId,
            label: res.name,
          })
        })
      })
    },
  },
});
</script>
<style lang="less">
</style>
      