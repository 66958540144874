
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  onBeforeMount,
  getCurrentInstance,
  ComponentInternalInstance,
  createVNode,
} from "vue";
import pagaTable from "@/components/pagination-table/index";
import { TransformCellTextProps } from "ant-design-vue/lib/table/interface";
import formSearch from "@/components/page-search/form";
import popupWindow from "./popupNew.vue";
import popupRandom from "./popupRandom.vue";
import { ExaminationPaperControllerPage, ExaminationPaperController_del, ExaminationPaperControllerUpdateStatus_put, ExaminationPaperController_id } from '@/api/api';
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { ExaminationPaperController,ExaminationPaperControllerDownloadSelectData } from '@/api/downloadApi'
import {blob} from '@/utils/blob'
import { message } from 'ant-design-vue';

const purchaseColumns = [
  {
    title: "序号",
    width: 60,
    dataIndex: "index",
    customRender: (row: TransformCellTextProps) => row.index + 1,
  },
  {
    title: "考卷名称 ",
    dataIndex: "title",
    slots: { customRender: "title" },
    width: 200,
    ellipsis: true,
  },
  {
    title: "考卷总分",
    dataIndex: "totalPoints",
  },

  {
    title: "考卷类型",
    dataIndex: "type",
    customRender: (text: TransformCellTextProps) => {
      return (text.text == 1 ? "选题组卷" : "随机组卷")
    },
  },
  // {
  //   title: "考卷状态",
  //   dataIndex: "status",
  //   customRender: (text: TransformCellTextProps) => {
  //     return (text.text == 0 ? "未发布" : "已发布")
  //   },
  // },
  {
    title: "创建人",
    dataIndex: "createdBy",
  },
  {
    title: "创建时间",
    dataIndex: "createdTime",
    sorter: true,
  },
  {
    title: "更新人",
    dataIndex: "updatedBy",
  },
  {
    title: "更新时间",
    dataIndex: "updatedTime",
  },
  {
    title: "操作",
    dataIndex: "operation",
    width: 200,
    slots: { customRender: "operation" },
  },
];
const columns = [
  {
    title: "序号",
    width: 60,
    dataIndex: "index",
    customRender: (row: TransformCellTextProps) => row.index + 1,
  },
  {
    title: "问题描述 ",
    dataIndex: "title",
    slots: { customRender: "title" },
    width: 200
  },
  {
    title: "问题类型",
    dataIndex: "category",
  },

  {
    title: "答案",
    dataIndex: "answer",
  },
  {
    title: "答案解析",
    dataIndex: "analysis",
  },
];
export default defineComponent({
  name: "questionType",
  components: {
    pagaTable,
    popupWindow,
    popupRandom,
    formSearch,
  },
  setup(props: any) {
    const { proxy } = getCurrentInstance() as ComponentInternalInstance;

    const data = reactive({
      purchaseColumns,
      selectedRowKeys: [],
      extraModel: {},
      formBtn: {
        colProps: { span: 6 },
        formItemProps: {
          wrapperCol: {
            span: 24,
          },
        },
      },
      formConfig: [
        //试题描述：
        {
          colProps: {
            span: 6,
          },
          formItemProps: {
            label: "考卷名称：",
          },
          comConfig: {
            tag: "input", // input, select, date-picker
            key: "title", // 即是插槽也是字段model key
            props: {
              placeholder: "请输入",
            },
          },
        },
        //试题类别：
        {
          rowProps: {},
          colProps: {
            span: 6,
          },
          formItemProps: {
            label: "生成类型：",
          },
          comConfig: {
            tag: "select",
            key: "type",
            valueKey: "id",
            labelKey: "name",
            getDataApi: () => {
              return new Promise((reslove) => {
                setTimeout(() => {
                  reslove([
                    {
                      name: "选题组卷",
                      id: 1,
                    },
                    {
                      name: "随机组卷",
                      id: 2,
                    },
                  ]);
                }, 500);
              });
            },
            props: {
              placeholder: "请输入",
            },
          },
        },
        // 更新时间
        {
          rowProps: {},
          colProps: {
            span: 6,
          },
          formItemProps: {
            label: "创建时间：",
          },
          comConfig: {
            tag: "input",
            key: "testSlots",
            props: {
              placeholder: "请输入",
            },
          },
        },
      ],
    });
    const onSelectChange = (selectedRowKeys: never[]) => {
      data.selectedRowKeys = selectedRowKeys;
    };
    const table = ref();
    const params_api = ref()
    const getDataApi = (params: any) => {
      return new Promise((reslove) => {
        params_api.value = {
          "currentPage": params.pageIndex,
          "pageSize": params.pageSize,
          ...formSearch.value.getQuery(),
          'startUpdateTime': updatedTime.value[0],
          'endUpdateTime': updatedTime.value[1],
          sort: params.sort
        }
        ExaminationPaperControllerPage(params_api.value).then(res => {
          reslove(res)
        })
      });
    };
    function handleDownload() {
      ExaminationPaperController({ ...params_api.value }).then(res => {
        blob(res)
      })
    }
    function downloadSelectApi() {
      if(data.selectedRowKeys.length>0){
         ExaminationPaperControllerDownloadSelectData({ ids:data.selectedRowKeys }).then(res => {
          blob(res)
        })
      }else{
        message.warning('请先选择需要下载的内容');
      }
    }
    const handleChange = (
      pagination: any,
      filter: any,
      sorter: any,
      { currentDataSource }: any
    ) => {
      console.log(pagination, filter, sorter, currentDataSource);
    };
    const handleClick = (text: string, row: any) => {
      Title.value = "编辑试题";
      if (row.type == 1) {
        popupWindow.value.showModal(row.examinationPaperId);
      } else {
        popupRandom.value.showModal(row.examinationPaperId);
      }

    };
    const formSearch = ref();
    const popupWindow = ref();
    const popupRandom = ref();
    const Title = ref();
    const updatedTime = ref([]);
    const handleAdd = () => {
      Title.value = "选题组卷";
      popupWindow.value.showModal();
    };
    const handleSearch = (formState: any) => {
      // formSearch.value.getQuery();
      // console.log({ ...formSearch.value.getQuery() });
      // console.log("插槽", { ...updatedTime.value });
      table.value.refresh();
    };
    const randomClick = () => {
      Title.value = "随机组卷";
      popupRandom.value.showModal();
    };
    const refresh = () => {
      table.value.refresh();
      data.selectedRowKeys = []
    }
    const handleDeleteAll = () => {
      (proxy as any).$confirm({
        title: "删除确认",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: "确认删除选中考卷吗？",
        okType: " ",
        onOk: () => {
          return new Promise((resolve) => {
            ExaminationPaperController_del({ id: data.selectedRowKeys.join(',') }).then(() => {
              data.selectedRowKeys = []
              refresh();
            })
              .finally(() => {
                resolve(true)
                refresh();
              });
          });
        },
      });
    };

    const handleUpdateStatus = (status: string) => {
      const statusName = status === '1' ? '发布' : '取消发布';
      (proxy as any).$confirm({
        title: `${statusName}确认`,
        icon: () => createVNode(ExclamationCircleOutlined),
        content: `确认${statusName}选中考卷吗？`,
        onOk: () => {
          return new Promise((resolve) => {
            ExaminationPaperControllerUpdateStatus_put({
              status,
              examinationPaperIds: data.selectedRowKeys
            }).then(() => {
              (proxy as any).$message.success(`资料${statusName}成功`);
              refresh();
            }).finally(() => {
              resolve(true)
              refresh();
            })
          });
        },
      });
    }
    const visible = ref<boolean>(false);
    const questions = ref([] as any[])
    const lookClick = (val: any) => {
      visible.value = true;
      ExaminationPaperController_id(val).then(res => {
        questions.value = res.data.examinationPaperToQuestions
      })
    };

    const handleOk = (e: MouseEvent) => {
      visible.value = false;
    };
    const reset = () => {
      updatedTime.value = []
    };
    return {
      downloadSelectApi,
      handleDownload,
      reset,
      columns,
      questions,
      visible,
      handleOk,
      handleDeleteAll,
      handleUpdateStatus,
      refresh,
      randomClick,
      lookClick,
      ...toRefs(data),
      table,
      onSelectChange,
      getDataApi,
      handleChange,
      handleClick,
      formSearch,
      popupWindow,
      popupRandom,
      Title,
      handleAdd,
      handleSearch,
      updatedTime,
    };
  },
});
