<template>
  <div>
    <a-modal
      width="1100px"
      :title="Title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="cancel"
    >
      <div style="height:600px;overflow:auto;padding:10px;">
        <a-form
          ref="formRef"
          :model="formState"
          :rules="rules"
        >
          <a-form-item
            label="试卷名称："
            name="title"
          >
            <a-input
              allow-clear
              placeholder="请输入"
              v-model:value="formState.title"
              style="width:80%"
            />
          </a-form-item>
          <a-form-item label="试卷描述：">
            <a-textarea
              allow-clear
              placeholder="请输入"
              v-model:value="formState.comment"
              style="width:80%"
            />
          </a-form-item>
          <a-form-item
            label="试卷总分："
            name="title"
          >
            <a-input
              disabled
              placeholder="根据选题分值自动计算"
              v-model:value="formState.totalPoints"
              style="width:80%"
            />
          </a-form-item>
          <div style="width:100%;border: 1px solid rgb(225, 225, 225);padding:10px;position:relative;">
            <div style="height:40px;line-height: 40px;background:rgb(225, 225, 225);">
              <h2 style="margin-left:10px">选题信息</h2>
            </div>
            <a-button
              style="position:relative;top:5px;left:80%"
              @click="add"
              type="primary"
               
            >添加题目</a-button>
            <a-button
              @click="delTable()"
              type="primary"
               
              style="position:relative;top:5px;left:80%"
            >删除</a-button>
            <a-table
              style="margin-top: 10px"
              :columns="columns"
              :data-source="sourceData"
              :pagination="false"
              :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
              :row-key="record => record.questionId"
            >
              <template #score="{column, text, record }">
                <template v-if="column.dataIndex === 'score'">
                  <div class="editable-cell">
                    <div
                      v-if="editableData[record.questionId]"
                      class="editable-cell-input-wrapper"
                    >
                      <a-input v-model:value="editableData[record.questionId].score" />
                      <check-outlined
                        class="editable-cell-icon-check"
                        @click="save(record.questionId)"
                      />
                    </div>
                    <div
                      class="editable-cell-text-wrapper"
                      v-else
                    >
                      {{ text || ' ' }}
                      <edit-outlined
                        class="editable-cell-icon"
                        @click="edit(record.questionId)"
                      />
                    </div>
                  </div>
                </template>
              </template>
              <template #operation="{ text, record,index }">
                <a-button
                  type="link"
                  style=" "
                  @click="del(index)"
                >删除</a-button>
              </template>
            </a-table>
          </div>
        </a-form>
      </div>
    </a-modal>
    <AddTitle
      ref="AddTitle"
      :checkIds="checkIds"
    ></AddTitle>
  </div>
</template>
<script>

import { defineComponent, reactive, defineAynscComponent } from "vue";
import AddTitle from "./AddTitle.vue";
import { ExaminationPaperController_post, ExaminationPaperController_id, ExaminationPaperController_put } from '@/api/api'
import { CheckOutlined, EditOutlined, HomeOutlined } from '@ant-design/icons-vue';
import { cloneDeep } from 'lodash-es';
const columns = [
  {
    title: "序号",
    width: 60,
    dataIndex: "index",
    customRender: (row) => row.index + 1,
  },
  {
    title: '问题内容',
    dataIndex: 'title',
    width: 200,
    ellipsis: true,
  },
  {
    title: '问题类别',
    key: 'category',
    dataIndex: 'category',
  },
  {
    title: '问题类型',
    customRender: (text) => {
      return (text.text == 1 ? "单选题" : (text.text == 2 ? "多选题" : "判断题"))
    },
    key: 'type',
    dataIndex: "type",
  },
  {
    title: '分值',
    key: 'score',
    dataIndex: 'score',
    slots: { customRender: "score" },
  },
  {
    title: "操作",
    dataIndex: "operation",
    width:200,
    slots: { customRender: "operation" },
  },
];
export default defineComponent({
  components: {
    EditOutlined,
    CheckOutlined,
  },
  name: "index",
  props: {
    Title: {
      type: String,
      required: false,
    },
    FormData: {
      type: String,
      required: false,
    },
  },

  provide() {
    return {
      addTable: this.addTable,
      // delTable: this.delTable
    }
  },
  data() {
    return {
      editableData: {},
      formState: {
        title: '',
        comment: '',
        totalPoints: 0,
      },
      sourceData: [],
      selectedRowKeys: [],
      columns,
      visible: false,
      confirmLoading: false,
      examinationPaperToQuestions: [],
      editor: false,
      rules: {
        title: [{ required: true, message: '不能为空', trigger: 'blur' }],
      }
    };
  },
  computed: {
    checkIds() {
      return this.sourceData.map(item => item.questionId)
    }
  },
  components: {
    AddTitle
  },
  mounted() {
  },
  methods: {
    edit(questionId) {
      if (this.sourceData) {
        this.editableData[questionId] = cloneDeep(this.sourceData.filter(item => questionId === item.questionId)[0]);
      }
    },
    save(questionId) {
      Object.assign(this.sourceData.filter(item => questionId === item.questionId)[0], this.editableData[questionId]);
      delete this.editableData[questionId];
      this.formState.totalPoints = 0
      this.sourceData.forEach(res => {
        this.formState.totalPoints = Number(this.formState.totalPoints) + Number(res.score)
      })
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    del(index) {
      this.sourceData.splice(index, 1)
      this.formState.totalPoints = 0
      this.sourceData.forEach(res => {
        this.formState.totalPoints = Number(this.formState.totalPoints) + Number(res.score)
      })
    },
    add() {
      this.$refs.AddTitle.showModal('添加题目')
    },
    cancel() {
      this.$refs.formRef.resetFields()
      this.visible = false;
      this.editor = false
      this.sourceData = []
      this.formState = {
        title: '',
        comment: '',
        totalPoints: 0,
      }
    },
    handleOk() {
      this.$refs.formRef.validate().then(() => {
        this.confirmLoading = true;
        this.examinationPaperToQuestions = this.sourceData
        const params = {
          ...this.formState,
          examinationPaperToQuestions: this.examinationPaperToQuestions,
          type: 1
        }
        if (!this.editor) {
          ExaminationPaperController_post(params).then(res => {
            this.visible = false;
            this.confirmLoading = false;
            this.$parent.refresh();
          })
        } else {
          ExaminationPaperController_put(params).then(res => {
            this.visible = false;
            this.confirmLoading = false;
            this.$parent.refresh();
          })
        }
      });
    },
    showModal(val) {
      this.visible = true;
      this.editor = false
      if (val) {
        this.editor = true
        ExaminationPaperController_id(val).then(res => {
          this.formState = res.data
          this.sourceData = res.data.examinationPaperToQuestions
        })
      }
    },
    addTable(arr) {
      this.sourceData.push(arr)
      this.formState.totalPoints = 0
      this.sourceData.forEach(res => {
        this.formState.totalPoints = Number(this.formState.totalPoints) + Number(res.score)
      })
    },
    delTable() {
      this.sourceData = this.sourceData.filter(item => !this.selectedRowKeys.includes(item.questionId))
      this.selectedRowKeys = []
      this.formState.totalPoints = 0
      this.sourceData.forEach(res => {
        this.formState.totalPoints = Number(this.formState.totalPoints) + Number(res.score)
      })
    }
  },
});
</script>
<style lang="less">
.editable-cell {
  position: relative;
  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check {
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
  }

  .editable-cell-icon {
    margin-top: 4px;
    display: none;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
}
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
</style>
    